import React, { Component } from 'react';

import ExploreHeader from './explore-header';
import ExploreResultsHeader from './explore-results-header';
import ExploreSidebar from './explore-sidebar';
import ExploreResultsGrid from './explore-results-grid';
import ExploreResultsMap from './explore-results-map';
import sortingFunctions from '../../utils/sorting-functions';

import gridActiveIcon from '../../images/icons/icon-grid.svg';
import gridIcon from '../../images/icons/icon-grid-inactive.svg';
import mapActiveIcon from '../../images/icons/icon-map-active.svg';
import mapIcon from '../../images/icons/icon-map.svg';
import iconFilter from '../../images/icons/icon-filter.svg';
import { deepCloneArray } from '../../utils/deep-clone';

/**
 * Explore page wrapper
 */
class ExplorePage extends Component {

    state = {
        results: this.props.schools,
        activeView: 'grid',
        isInPrintMode: false
    };
        
    sidebar = React.createRef();
    exploreResultsHeader = React.createRef();
    exploreCards = (this.props.node.relationships.field_components &&
        this.props.node.relationships.field_components[0].relationships &&
        this.props.node.relationships.field_components[0].relationships.field_cards
        ) ? this.props.node.relationships.field_components[0].relationships.field_cards : [];

    componentDidMount = () => {
        this.addTrailingSlash();
        this.loadInitialFilters();
    }

    addTrailingSlash = () => {
        if (typeof window !== undefined) {
            const pathname = window.location.pathname.endsWith('/') ? window.location.pathname : `${window.location.pathname}/`;
            const url = window.location.origin + pathname + window.location.search;
            window.history.replaceState({ url }, '', url);
        }
    }

    updateResults = results => {
        // TODO: store the sorting function preference inside explore-page instead of the results-header
        if (this.exploreResultsHeader && this.exploreResultsHeader.current) {
            results = this.sortResults(results, this.exploreResultsHeader.current.getSortBy());
        }
        this.setState({ results });
    }

    setPrintMode (isInPrintMode, callback = () => {}) {
        this.setState({ isInPrintMode }, () => callback());
    }

    sortResults = (results, sortBy) =>
        [...results].sort((currentSchool, nextSchool) => sortingFunctions[sortBy](currentSchool, nextSchool));

    isMatch = (school, searchQuery) =>
        school.getName().toLowerCase().includes(searchQuery) ||
        school.getAlternateNames().toLowerCase().includes(searchQuery) ||
        school.hasLocationOrZipExactMatch(searchQuery);

    findSearchQueryResults = searchQuery => this.props.schools
        .filter(school => this.isMatch(school, searchQuery.toLowerCase()))
        .sort(current => current.getName().toLowerCase().includes(searchQuery.toLowerCase()) ? -1 : 1);

    generateFinalResults = (filterResults, searchResults) =>
        filterResults.filter(filterResult => searchResults.some(searchResult => filterResult === searchResult));

    isPassingFilters = (filters, school) => {
        const activeFilters = deepCloneArray(filters)
            .filter(filter => this.hasActiveControls(filter))
            .map(filter => Object.assign({}, filter, { controls: filter.controls.filter(control => control.isActive), isPassing: false }));        

        school.getTags().forEach(tag => activeFilters.forEach(filter => filter.controls.forEach(control => {
            if (control.name === tag) {
                control.isPassing = true;
            }
        })));

        const finalFilters = activeFilters.map(filter => Object.assign({}, filter, { isPassing: filter.controls.some(control => control.isPassing) }));

        const locationFilter = finalFilters.find(filter => filter.title === 'Location');
        if (locationFilter) {
            let country = locationFilter.controls.find(control => control.id === 'countryLocation')?.value
            let state = locationFilter.controls.find(control => control.id === 'stateLocation')?.value
            locationFilter.isPassing = school.hasStateOrCountry(country, state);
        }

        return finalFilters.every(filter => filter.isPassing);
    }

    findFilterResults = filters => this.props.schools.filter(school => this.isPassingFilters(filters, school))

    hasActiveControls = filter => filter.controls.some(filterControl => filterControl.isActive);

    setPageResults = (filters, searchQuery) => {
        const filterResults = filters.length ? this.findFilterResults(filters) : this.props.schools;
        const searchResults = searchQuery ? this.findSearchQueryResults(searchQuery) : this.props.schools;
        const results = this.generateFinalResults(filterResults, searchResults);
        this.updateResults(results);
    }

    loadInitialFilters = () => {
        const params = new URLSearchParams(window.location.search);
        const filterParams = params.get('filter');
        const searchQueryParam = params.get('q');
        
        this.handleURLResults(filterParams, searchQueryParam);
    }

    handleURLResults = (filterParams, searchQueryParam) => {
        const filters = filterParams ? JSON.parse(atob(filterParams)) : [];
        const searchQuery = searchQueryParam ? atob(searchQueryParam).toLowerCase() : '';
        this.setPageResults(filters, searchQuery);
    }

    activateView = mode => this.setState({ activeView: mode })

    isInMapMode = () => this.state.activeView === 'map'

    renderViewControls = () => (
        <div className="view-controls">
            <div className="container">
                <div>
                    <div className={`view-control-button-container ${this.state.activeView === 'grid' ? 'view-control-button-container--active' : ''}`}>
                        <button className="view-control-button" onClick={() => this.activateView('grid')}>
                            <img alt="Three by three dot matrix." src={this.state.activeView === 'grid' ? gridActiveIcon : gridIcon}/>
                            Grid view
                        </button>
                    </div>
                    <div className={`view-control-button-container ${this.state.activeView === 'map' ? 'view-control-button-container--active' : ''}`}>
                        <button className="view-control-button" onClick={() => this.activateView('map')}>
                            <img alt="Flag." src={this.state.activeView === 'map' ? mapActiveIcon : mapIcon}/>
                            Map view
                        </button>
                    </div>
                </div>
                <div className="filters-drawer-control" onClick={() => this.sidebar.current.toggleFiltersDrawer()}>
                    <img src={iconFilter} alt="Funnel." />
                    Filters
                    <button>
                        &#65291;
                    </button>
                </div>
            </div>
        </div>
    )

    render = () => (
        <>
            <ExploreHeader findMatches={this.findSearchQueryResults} setPageResults={this.setPageResults} />
            <section className={`explore-layout ${this.isInMapMode() ? 'main--map-mode' : ''}`}>
                <ExploreSidebar ref={this.sidebar} setPageResults={this.setPageResults} />
                <div className="main">
                    {this.renderViewControls()}
                    <ExploreResultsHeader schools={this.state.results}
                                            updateResults={this.updateResults.bind(this)}
                                            setPrintMode={(isInPrintMode, callback) => this.setPrintMode(isInPrintMode, callback)}
                                            view={this.state.activeView}
                                            ref={this.exploreResultsHeader} />

                    {this.state.activeView === 'grid' ? (
                        <ExploreResultsGrid schools={this.state.results} cards={this.exploreCards} isInPrintMode={this.state.isInPrintMode} />
                    ) : <ExploreResultsMap allSchools={this.props.schools} results={this.state.results} /> }
                </div>
            </section>
        </>
    )

};

export default ExplorePage;