import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import iconArrow from '../images/icons/icon-arrow.svg';
import iconLocation from '../images/icons/icon-location.svg';
import iconWebinar from '../images/icons/icon-webinar.svg';
import iconFilter from '../images/icons/icon-filter.svg';
import eventImageFallback from '../images/events/event-4.jpg';
import { deepCloneArray } from '../utils/deep-clone';
import removeObjectDuplicates from '../utils/remove-object-duplicates';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from './internal/loader';

/**
 * This components renders Events
 */
export default class Events extends Component {

    /**
     * Constuct events component
     * @param {Object} props
     */
    constructor (props) {
        super(props);
        this.state = {
            areTypeControlsExpanded: true,
            areYearControlsExpanded: false,
            isFiltersDrawerActive: false,
            events: [],
            eventTypes: [],
            eventYears: [],
            eventsByMonth: []
        };
    }

    loadInitialBatch (events) {
        const initialBatchSize = 10;
        for (let i = 0; i < initialBatchSize; i++) {
            if (events[i]) {
                events[i].isLoaded = true;
            } else {
                break;
            }
        }
    }

    /**
     * Get a list of events by month
     * @param {Array} events 
     */
    getEventsByMonth (events) {
        const eventsByMonth = [];
        events.forEach(event => {
            const date = moment(event.field_start_date).format('YYYY-MM');
            const index = eventsByMonth.findIndex(month => month.date === date);
            if (index === -1) {
                eventsByMonth.push({ date, events: [event] });
            } else {
                eventsByMonth[index].events.push(event);
            }
        });
        return eventsByMonth;
    }

    /**
     * Store event types, event years, and events into state
     * @param {Array} edges
     */
    storeInState (edges) {
        if (!this.state.events.length) {
            const events = edges.map(edge => edge.node);
            this.loadInitialBatch(events);

            const duplicateTypes = events.map(event => event.relationships.field_event_type);
            const uniqueTypes = removeObjectDuplicates(duplicateTypes, 'name');
            
            const duplicateYears = events.map(event => ({ name: moment(event.field_start_date).format('YYYY') }));
            const uniqueYears = removeObjectDuplicates(duplicateYears, 'name');

            this.setState({
                events,
                eventsByMonth: this.getEventsByMonth(events),
                eventTypes: uniqueTypes,
                eventYears: uniqueYears
            });
        }
    }

    /**
     * Render button that closes full screen filter modal
     * @returns {Object}
     */
    renderFiltersCloseButton () {
        return (
            <div className="filters-drawer-header">
                <div>
                    <img src={iconFilter} alt="Funnel."/>
                    <span>Filters</span>
                </div>
                <button className="close-button" onClick={() => this.toggleFiltersDrawer()}>&#65293;</button>
            </div>
        );
    }

    /**
     * Toggle visibility of event type checkboxes
     */
    onToggleTypeControls () {
        this.setState({ areTypeControlsExpanded: !this.state.areTypeControlsExpanded });
    }


    /**
     * Toggle event type in state
     * @param {Object} eventType
     */
    onEventTypeToggle (eventType) {
        const newEventTypes = deepCloneArray(this.state.eventTypes);
        const changedEventType = newEventTypes
            .find(newEventType => newEventType.name === eventType.name);
        changedEventType.isActive = !changedEventType.isActive;
        this.setState({ eventTypes: newEventTypes });
    }

    /**
     * Render event type checkboxes
     * @returns {Object}
     */
    renderEventTypeFilters () {
        return (
            <div className={`type-controls-container ${this.state.areTypeControlsExpanded ? 'type-controls-container--is-expanded' : ''}`}>
                <button
                    className="type-controls-button"
                    onClick={() => this.onToggleTypeControls()}>
                    <span>Type</span>
                    <img src={iconArrow} alt="Arrow." />
                </button>
                <ul className="type-list">
                    {this.state.eventTypes.map((eventType, key) =>
                        <li key={key} className="type-item">
                            <input
                                id={eventType.name}
                                type="checkbox"
                                onChange={() => this.onEventTypeToggle(eventType)}
                                checked={eventType.isActive || false}
                                />
                            <label htmlFor={eventType.name}>{eventType.name}</label>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    /**
     * Toggle visibility of event year checkboxes
     */
    onToggleYearControls () {
        this.setState({ areYearControlsExpanded: !this.state.areYearControlsExpanded });
    }

    /**
     * Toggle event year in state
     * @param {Object} eventYear
     */
    onEventYearToggle (eventYear) {
        const newEventYears = deepCloneArray(this.state.eventYears);
        const changedEventYear = newEventYears
            .find(newEventYear => newEventYear.name === eventYear.name);
        changedEventYear.isActive = !changedEventYear.isActive;
        this.setState({ eventYears: newEventYears });
    }

    /**
     * Render event year checkboxes
     * @returns {Object}
     */
    renderEventYearFilters () {
        return (
            <div className={`year-controls-container ${this.state.areYearControlsExpanded ? 'year-controls-container--is-expanded' : ''}`}>
                <button className="year-controls-button" onClick={() => this.onToggleYearControls()}>
                    <span>Year</span>
                    <img src={iconArrow} alt="Arrow." />
                </button>
                <ul className="year-list">
                    {this.state.eventYears.map((eventYear, key) =>
                        <li key={key} className="year-item">
                            <input
                                id={`year-${eventYear.name}`}
                                type="checkbox"
                                onChange={() => this.onEventYearToggle(eventYear)}
                                checked={eventYear.isActive || false}
                                />
                            <label htmlFor={`year-${eventYear.name}`}>{eventYear.name}</label>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    /**
     * Render sidebar containing filters
     * @returns {Object}
     */
    renderSidebar () {
        return (
            <aside className={`col-lg-4 ${this.state.isFiltersDrawerActive ? 'filter-drawer' : ''}`}>
                {this.renderFiltersCloseButton()}
                {this.renderEventTypeFilters()}
                {this.renderEventYearFilters()}
            </aside>
        );
    }

    /**
     * Check if event type is active
     * @param {Object} eventType 
     * @returns {boolean}
     */
    isActiveType (eventType) {
        return !this.state.eventTypes.some(type => type.isActive) ||
            this.state.eventTypes.some(type => type.isActive && type.name === eventType.name);
    }

    /**
     * Check if event year is active
     * @param {Object} eventYear
     * @returns {boolean}
     */
    isActiveYear (eventYear) {
        return !this.state.eventYears.some(year => year.isActive) ||
            this.state.eventYears.some(year => year.isActive && year.name === eventYear);
    }

    /**
     * Check if event satisfies filtering criteria
     * @param {Object} event 
     * @returns {boolean}
     */
    shouldRenderEvent (event) {
        return this.isActiveType(event.relationships.field_event_type) &&
            this.isActiveYear(moment(event.field_start_date).format('YYYY')) &&
            event.isLoaded;
    }

    /**
     * Render webinar sign
     * @returns {Object}
     */
    renderWebinarSign () {
        return (
            <>
                <img src={iconWebinar} alt="Desktop computer."/>
                <span>Webinar</span>
            </>
        );
    }

    /**
     * Render location
     * @param {String} location 
     * @returns {Object}
     */
    renderLocation (location) {
        return  location ? 
            (<>
                <img src={iconLocation} alt="Location marker." />
                <span>{location}</span>
            </>) :
            null;
    }

    /**
     * Render location details
     * @param {Object} eventType 
     * @param {String} location 
     * @returns {Object}
     */
    renderLocationDetails (eventType, location) {
        return (
            <div className="location">
                { eventType.name === 'Webinar' ? this.renderWebinarSign() :this.renderLocation(location) }
            </div>
        );
    }

    /**
     * Render event image
     * @param {Object} image 
     * @returns {Object}
     */
    renderEventImage (image, alt) {
        return (
            <div className="image-container">
                {
                    image ?
                        <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} className="" alt={alt} /> :
                        <img src={eventImageFallback} alt="Stylized calendar." />
                }
            </div>
        );
    }

    /**
     * Render individual event
     * @param {Object} event
     * @param {number} key
     * @returns {Object}
     */
    renderEvent (event, key) {    
        return (
            <li key={key} className="event-container">
                <div className="date-details">
                    <span className="day">{moment(event.field_start_date).format('D')}</span>
                    <span className="month">{moment(event.field_start_date).format('MMM')}</span>
                </div>
                <div className="event-details">
                    <Link to={event.path.alias}>{event.title}</Link>
                    <p>{event.field_description.summary}</p>
                    { this.renderLocationDetails(event.relationships.field_event_type, event.field_location_short) }
                </div>
                <Link to={event.path.alias}>
                  { this.renderEventImage(event.relationships.field_event_image, event.field_event_image.alt) }
                </Link>
            </li>
        )
    }

    /**
     * Render events for a given month
     * @param {Array} month 
     * @param {number} key 
     */
    renderMonthEvents (month, key) {
        return (
            <div key={key}>
                <span className="month-date">
                    { moment(month.date).format('MMMM YYYY') }
                </span>
                <ul className="event-list" key={key}>
                    {
                        month.events.map((event, key) => this.shouldRenderEvent(event) ?
                            this.renderEvent(event, key) :
                            null)
                    }
                </ul>
            </div>
        );
    }

    /**
     * Toggle full screen filters modal
     */
    toggleFiltersDrawer () {
        this.setState({ isFiltersDrawerActive: !this.state.isFiltersDrawerActive });
    }

    shouldRenderMonth (monthEvents) {
        return this.state.eventYears.some(eventYear => eventYear.name === moment(monthEvents.date).format('YYYY')) &&
            monthEvents.events.some(event => this.shouldRenderEvent(event));
    }

    findNextUnloadedEvent (events) {
        return events.find(event => !event.isLoaded);
    }

    loadMoreEvents () {
        const batchSize = 5;
        const updatedEvents = deepCloneArray(this.state.events);
        const updatedEventsByMonth = this.getEventsByMonth(updatedEvents);
        for (let i = 0; i < batchSize; i++) {
            const nextUnloadedEvent = this.findNextUnloadedEvent(updatedEvents);
            if (nextUnloadedEvent) {
                nextUnloadedEvent.isLoaded = true;
            } else {
                break;
            }
        }
        this.setState({ events: updatedEvents, eventsByMonth: updatedEventsByMonth });
    }

    /**
     * Render events by month
     */
    renderEvents () {
        return (
            <main className="col-lg-8">
                <div className="filters-drawer-control">
                    <div>
                        <img src={iconFilter} alt="Funnel."/>
                        <span>Filters</span>
                    </div>
                    <button onClick={() => this.toggleFiltersDrawer()}>
                        <span>&#65291;</span>
                    </button>
                </div>
                <InfiniteScroll
                    loadMore={() => this.loadMoreEvents()}
                    hasMore={this.state.events.some(event => !event.isLoaded)}
                    loader={<Loader />}>
                    {
                        this.state.eventsByMonth
                            .filter(monthEvents => this.shouldRenderMonth(monthEvents))
                            .map((monthEvents, key) => this.renderMonthEvents(monthEvents, key))
                    }
                </InfiniteScroll>
            </main>
        )
    }

    /**
     * Render events component
     * @returns {Object}
     */
    render () {
        return (
            <StaticQuery
                query={graphql`
                    query EventsListingQuery {
                      allNodeEvent(sort: {fields: field_start_date, order: ASC}, filter: {path: {alias: {glob: "!/system/*"}}, status: {eq: true}}) {
                        edges {
                          node {
                            title
                            created

                            field_end_date
                            field_start_date


                            start_month_year:field_start_date(formatString: "MMMM YYYY")
                            start_day:field_start_date(formatString: "d")
                            start_month_short:field_start_date(formatString: "MMM")

                            field_location
                            field_location_short
                            field_registration_link {
                              uri
                              title
                            }
                            field_description {
                              processed
                              summary
                            }

                            path {
                              alias
                            }

                            field_event_image{
                                alt
                            }

                            relationships {

                              field_components {
                                __typename
                              }

                              field_event_type {
                                name
                              }

                              field_event_image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width:300 layout: FIXED)
                                    }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                `}
                render={
                    data => {
                        if(data.allNodeEvent.edges.length === 0) {
                            //when theres no events
                            return (
                                <div className="container events-container">
                                    <div className="no-events">
                                        There are no events at this time.
                                    </div>
                                </div>
                            );
                        } else {
                            this.storeInState(data.allNodeEvent.edges);
                            return (
                                <div className="container events-container">
                                    <div className="row">
                                        {this.renderSidebar()}
                                        {this.renderEvents()}
                                    </div>
                                </div>
                            );
                        }
                }}
            />
        );
    }
};
