import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slider from "react-slick";
import eventImageFallback from "../images/events/event-4.jpg";

/**
 *
 */
export default class BlogPostsCards extends Component {


	/**
	 * Component constructor
	 * @param props
	 */
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            tabletView: false,
            mobileView: false
        };
    }

    componentDidMount() {
        this.getViewportWidth();
        window.addEventListener('resize', this.getViewportWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getViewportWidth);
    }

    getViewportWidth = () => {
        const innerWidth = Math.min(window.innerWidth, window.screen.width);
        const tabletView = innerWidth < 992;
        const mobileView = innerWidth >= 768;
        this.setState({ tabletView });
        this.setState({ mobileView });
    };


    stringTruncate(str) {
        var length = 70;
        var dots = str.length > length ? '...' : '';

        if ((this.state.tabletView) && (this.state.mobileView)) {
            return str.substring(0, 70) + dots;
        }
        return str.substring(0, 120);
    }

    /**
     * Render event image
     * @param {Object} image
     * @returns {Object}
     */
    renderBlogImage (image, alt) {
        return (
            <div className="image-container">
                {   
                    (image && image.localFile && image.localFile.childImageSharp) ?
                        <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={alt} /> :
                        <img src={eventImageFallback} alt="Blog" />
                }
            </div>
        );
    }


    renderBlogCard (blog, index) {
        return (
            <div key={index} className="col-parrent">
                <div className="col">
                    <div className="img-holder">
                    	<Link to={blog.path.alias}>
                            {this.renderBlogImage(blog.relationships.field_blog_image, blog.field_blog_image.alt)}
                        </Link>
                    </div>
                    <div className="details-holder">
                        <h4>
                            <Link to={blog.path.alias}>{this.stringTruncate(blog.title)}</Link>
                        </h4>
                        <small>{blog.created_formatted}</small>
                    </div>
                </div>
            </div>
        );
    }


	/**
	 * Component renderer
	 * @returns {*}
	 */
    render() {

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                { breakpoint: 767, settings: { slidesToShow: 1 } },
                { breakpoint: 1024, settings: { slidesToShow: 3 } },
                { breakpoint: 100000, settings: { slidesToShow: 3 } }
            ]
        };

        return (
            <StaticQuery
                query={graphql`
                    query BlogPostListingQueryr {
                        allNodeBlogPost(limit: 3, sort: {fields: created, order: DESC}, filter: {path: {alias: {glob: "!/system/*"}}, status: {eq: true}}) {
                        edges {
                          node {
                            title
                            field_blog_author
                            field_blog_image {
                                alt
                            }
                            field_summary
                            created
                            created_formatted:created(formatString: "MMMM DD, YYYY")
                            path {
                              alias
                            }
                            relationships {
                              field_components {
                                __typename
                              }
                              field_blog_image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                              }
                              field_blog_audience {
                                id: drupal_internal__tid
                                name
                              }
                              field_blog_tags {
                                id: drupal_internal__tid
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                `}
                render={data => (
                    <section className="blog-posts">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2>News and updates</h2>
                                </div>
                            </div>
                            <div className="parrent">
                                <Slider {...settings}>
                                    {
                                        data.allNodeBlogPost.edges.map((edge, index) => this.renderBlogCard(edge.node, index))
                                    }
                                </Slider>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Link className="btn btn-blue btn-lg" to="/blog/">See more news</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            />
        )
    }

};

/**
 * @TODO Implement
 */
export const BlogPostsCardsQuery = graphql`
fragment ParagraphsBlogPostsCards on paragraph__blog_posts_cards {
  drupal_internal__id
}
`;
