import React, {Component} from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import SocialMediaShare from './internal/social-media-share';
import iconClock from '../images/icons/icon-clock.svg';
import iconLocation from '../images/icons/icon-location.svg';

/**
 * This components renders event header
 */
export default class EventHeader extends Component {

    /**
     * Render event start and end date/time
     * @returns {Object}
     */
    renderDateTime () {
        const startDateTime = this.props.node.field_start_date;
        const endDateTime = this.props.node.field_end_date;
       
        return (
            <>
                <div className="event-date-time-details">
                    <div>
                        <img src={iconClock} alt="Stylized lock." />
                        <span>Date and time</span>
                    </div>
                    <span>{ moment(startDateTime).format('MMMM D, YYYY') }–{ moment(endDateTime).format('MMMM D, YYYY') }</span>
                    <span>{ `${moment(startDateTime).format('h:mm a')}–${moment(endDateTime).format('h:mm a')}` }</span>
                </div>
            </>
        );
    }

    /**
     * Render event location
     * @returns {Object}
     */
    renderLocation () {

        if(!this.props.node.field_location)
            return (<></>)

        return (
            <>
                <div className="event-location-details">
                    <div>
                        <img src={iconLocation} alt="Map marker." />
                        <span>Location</span>
                    </div>
                    {
                        this.props.node.field_location.split('\n')
                            .map((addressLine, key) => (<span key={key}>{addressLine}</span>))
                    }
                </div>
            </>
        );
    }

    /**
     * Render registration link
     * @returns {Object}
     */
    renderRegistrationLink () {
        return this.props.node.field_registration_link ?
            (
                <div className="event-registration-link">
                    <a href={ this.props.node.field_registration_link.uri }>
                        {this.props.node.field_registration_link.title}
                    </a>
                </div>
            ) :
            null;
    }

    renderDescription () {
        return this.props.node.field_description ?
            (
                <div className="event-description"
                     dangerouslySetInnerHTML={{__html: this.props.node.field_description.processed }}>
                </div>
            ) :
            null;
    }

    /**
     * Render event header
     * @returns {Object}
     */
    render() {
        return (
            <section className="container event-header-container">
                <div className="col-lg-8 offset-lg-2 offset-xl-2">
                    <h1>{this.props.node.title}</h1>
                    <SocialMediaShare />
                    <div>
                        <div className="event-image">
                            {
                                (this.props.node.relationships.field_event_image &&
                                 this.props.node.relationships.field_event_image.localFile &&
                                 this.props.node.relationships.field_event_image.localFile.childImageSharp) ?
                                    (
                                        <GatsbyImage image={this.props.node.relationships.field_event_image.localFile.childImageSharp.gatsbyImageData} />
                                    ) : null
                            }
                        </div>
                    </div>
                    <div className="event-details">
                        { this.renderDateTime() }
                        { this.renderLocation() }
                        { this.renderRegistrationLink() }
                    </div>

                    { this.renderDescription() }
                </div>
                <Helmet>
                    <title>{this.props.node.title}</title>
                </Helmet>
            </section>
        );
    }
};
