import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import exploreFilters from './explore-filters';
import { deepCloneArray } from '../../utils/deep-clone';
import setSidebarLeftMargin from '../../utils/set-sidebar-margin';

import iconArrow from '../../images/icons/icon-arrow.svg';
import iconCancel from '../../images/icons/icon-cancel.svg';
import iconFilter from '../../images/icons/icon-filter.svg';

import { usaStates } from '../../constants/usa-states';
import { countries } from '../../constants/usa-states';

/**
 * Explore sidebar component containing filters
 */
class ExploreSidebar extends Component {

    state = {
        stateLocationQuery: '',
        countryLocationQuery: '',
        lastChangeTimestamp: null,
        filters: exploreFilters
    };

    sidebar = React.createRef();
    StateLocation = React.createRef();
    CountryLocation = React.createRef();

    componentDidMount = () => {
        setSidebarLeftMargin(this.sidebar.current);
        
        this.CountryLocation.current?.addEventListener('listboxClosed', () => this.handleCountryChange())
        this.CountryLocation.current?.addEventListener('valueChanged', () => this.handleCountryCleared())        
        this.StateLocation.current?.addEventListener('valueChanged', () => this.handleUSStateChange())
        this.StateLocation.current?.addEventListener('listboxOpened', () => this.handleUSStateZIndexOpen())
        this.StateLocation.current?.addEventListener('listboxClosed', () => this.handleUSStateZIndexClose())

        
        this.setActiveFiltersFromURL();

        if (typeof window !== 'undefined') {
            window.document.addEventListener('ca-toggle-filter', this.handleCardButton);
            window.addEventListener('resize', this.handleResize);
        }
    }

    handleCountryChange = () => {
        this.CountryLocation?.current?.getValue().then((country) => {
            this.setState({
                countryLocationQuery: country,
                lastChangeTimestamp: new Date().getTime()
            },() => {
                if(this.CountryLocation.current?.getAttribute("control")) {
                    this.debounceTextFilterCountry(JSON.parse(this.CountryLocation.current?.getAttribute("control")));
                }
            })         
        })
    }

    handleCountryCleared = () => {
        this.CountryLocation?.current?.getValue().then((country)=>{
            if(country==='') {
                this.handleCountryChange()
            }
        })
    }

    handleUSStateChange = () => {
        this.StateLocation?.current?.getValue().then((state) => {
            if(Array.isArray(state) && state.length === 0) {
                state = undefined;
            }
            this.setState({
                stateLocationQuery: state,
                lastChangeTimestamp: new Date().getTime()
            },() => {
                if(this.StateLocation.current?.getAttribute("control")) {
                    this.debounceTextFilter(JSON.parse(this.StateLocation.current?.getAttribute("control")));
                }
            })
        })
    }

    handleUSStateZIndexOpen = () => {
        document.getElementById('state-location').style.position = 'relative';
        document.getElementById('state-location').style.zIndex = 300;
    }

    handleUSStateZIndexClose = () => {
        document.getElementById('state-location').style.zIndex = 1;
    }
    
    handleCardButton = event => this.onControlChange(event.detail)
    
    handleResize = () => setSidebarLeftMargin(this.sidebar.current)
    
    componentWillUnmount = () => {
        window.document.removeEventListener('ca-toggle-filter', this.handleCardButton);
        window.removeEventListener('resize', this.handleResize);
        this.StateLocation.current?.removeEventListener('valueChanged', (e)=>this.handleUSStateChange(e))
        this.CountryLocation.current?.removeEventListener('listboxClosed', () => this.handleCountryChange())
    }

    setActiveFiltersFromURL = () => {
        const params = new URLSearchParams(window.location.search);
        const filterParams = params.get('filter');
        const filters = filterParams ? JSON.parse(atob(filterParams)) : exploreFilters;
        const stateLocationQuery = filters.find(filter => filter.title === 'Location').controls[0].value;
        this.setState({ filters, stateLocationQuery });
    }

    onToggleFilterVisibility = index => {
        const newFilters = deepCloneArray(this.state.filters);
        newFilters[index].isExpanded = !newFilters[index].isExpanded;
        this.setState({ filters: newFilters });
    }

    onControlChange = originalControlId => {
        if(originalControlId === 'countryLocation') {
            this.CountryLocation.current?.reset()
        }
        if(originalControlId === 'stateLocation') {
            this.StateLocation.current?.reset()
        }
        const newFilters = deepCloneArray(this.state.filters);
        const index = newFilters.findIndex(filter => filter.controls.some(control => control.id === originalControlId));
        const control = newFilters[index].controls.find(control => control.id === originalControlId);
        control.isActive = !control.isActive;
        this.setFilters(newFilters);
    }

    applyTextFilterCountry = textControl => {
        const newFilters = deepCloneArray(this.state.filters);
        const index = newFilters.findIndex(filter => filter.controls.some(control => control.id === textControl.id));
        const control = newFilters[index].controls.find(control => control.id === textControl.id);
        control.isActive = !!this.state.countryLocationQuery;
        control.value = this.state.countryLocationQuery;
        this.setFilters(newFilters);
    }

    applyTextFilterState = textControl => {
        const newFilters = deepCloneArray(this.state.filters);
        const index = newFilters.findIndex(filter => filter.controls.some(control => control.id === textControl.id));
        const control = newFilters[index].controls.find(control => control.id === textControl.id);
        control.isActive = !!this.state.stateLocationQuery;
        control.value = this.state.stateLocationQuery;
        this.setFilters(newFilters);
    }

    handleTextInput = (event, control) => {
        this.setState({
            stateLocationQuery: event.target.value,
            lastChangeTimestamp: new Date().getTime()
        }, () => {
            this.debounceTextFilter(control);
        });
    }

    debounceTextFilterCountry = textControl => {
        const debounceThreshold = 500;
        setTimeout(() => {
            if (new Date().getTime() - this.state.lastChangeTimestamp > debounceThreshold) {
                this.applyTextFilterCountry(textControl);
            }
        }, debounceThreshold);
    }

    debounceTextFilter = textControl => {
        const debounceThreshold = 500;
        setTimeout(() => {
            if (new Date().getTime() - this.state.lastChangeTimestamp > debounceThreshold) {
                this.applyTextFilterState(textControl);
            }
        }, debounceThreshold);
    }

    setFilters = filters => {
        this.setState({ filters });
        this.setPageResults(filters);
        this.updateURL(filters);
    }

    setPageResults = filters => {
        const params = new URLSearchParams(window.location.search);
        const searchQueryParam = params.get('q');
        const searchQuery = searchQueryParam ? atob(searchQueryParam).toLowerCase() : '';
        this.props.setPageResults(filters, searchQuery);
    }

    updateURL = filters => {
        const params = new URLSearchParams(window.location.search);
        params.set('filter', btoa(JSON.stringify(filters)));
        const pathname = window.location.pathname.endsWith('/') ? window.location.pathname : `${window.location.pathname}/`;
        const url = `${pathname}?${params.toString()}`;
        window.history.replaceState({url}, '', url);
    }
    
    clearFilters = () => {
        this.CountryLocation.current?.setValue('');
        this.StateLocation.current?.reset()
        const newFilters = deepCloneArray(this.state.filters);
        newFilters.forEach(filter => filter.controls.forEach(control => control.isActive = false));
        this.setFilters(newFilters);
    }
    
    capitalizeFirstLetter = (str)=> {
        return str.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { 
          return a.toUpperCase(); 
        });
    }
    
    renderTextInput = (control, index) => 
         control.id === 'countryLocation'?
            <forge-combobox id='country-location' key={index} ref={this.CountryLocation} label='Country' clearable='true' control={JSON.stringify(control)} placeholder='Choose an option'>
                {countries.map((country)=><forge-option key={country}>{country}</forge-option>)}
            </forge-combobox>
            : <> 
                <br></br>
                <forge-combobox id='state-location' key={index} ref={this.StateLocation} label='US State' clearable='true' control={JSON.stringify(control)} multiselect='true' placeholder='Choose one or more options'>
                    {Object.values(usaStates).map((state)=><forge-option key={state}>{this.capitalizeFirstLetter(state)}</forge-option>)}
                {/*<forge-option key='texas'> Texas <span className='magic'>TX</span></forge-option>*/}
                </forge-combobox> 
              </>

    removeStudentTypeSuffix = controlName => {
        const firstYearSuffix = ' - First Year';
        const transferSuffix = ' - Transfer';
        let controlNameWithoutSuffix = controlName;
        if (controlName.endsWith(firstYearSuffix)) {
            const startingIndex = controlName.indexOf(firstYearSuffix);
            controlNameWithoutSuffix = controlName.substring(0, startingIndex);
        } else if (controlName.endsWith(transferSuffix)) {
            const startingIndex = controlName.indexOf(transferSuffix);
            controlNameWithoutSuffix = controlName.substring(0, startingIndex);
        }
        return controlNameWithoutSuffix;
    };

    renderCheckbox = (control, index) => (
        <div className="checkbox-container" key={index}>
            <input
                onChange={() => this.onControlChange(control.id)}
                id={control.id}
                type="checkbox"
                checked={control.isActive || false} />
            <label htmlFor={control.id}>{this.removeStudentTypeSuffix(control.name)}</label>
        </div>
    )

    renderFilterControl = (control, index) => control.type === 'text' ?
        this.renderTextInput(control, index) :
        this.renderCheckbox(control, index)

    renderFilterControls = controls => (
        <div className="filter-content">
            { controls && controls.map(this.renderFilterControl) }
        </div>
    )

    renderFilter = (filter, index) => (
        <div key={index} className={`filter-container ${filter.isExpanded ? 'is-expanded' : ''}`}>
            { filter.title && this.renderFilterTitle(filter, index) }
            { filter.isExpanded && this.renderFilterControls(filter.controls) }
        </div>
    )

    renderFilterTitle = (filter, index) => (
        <div className='filter-title'>
            <button onClick={() => this.onToggleFilterVisibility(index)}>
                <span>{filter.title}</span>
                <img src={iconArrow} alt="icon arrow" />
            </button>
        </div>
    )

    toggleFiltersDrawer = () => {
        const isFiltersDrawerActive = !this.state.isFiltersDrawerActive;
        window.document.body.style.overflowY = isFiltersDrawerActive ? 'hidden' : 'auto';
        this.setState({ isFiltersDrawerActive });
        this.sidebar.current.scrollTop = 0;
    }

    renderPills = () => this.state.filters
        .map(filter => filter.controls)
        .reduce((accumulator, next) => accumulator.concat(next))
        .filter(control => control.isActive)
        .map((filter, index) => (
            <li key={index} className="active-filter-item">
                <button onClick={() => this.onControlChange(filter.id)}>{filter.name}</button>
            </li>
        ))

    renderActiveFilters = () => (
        <div className="active-filters-container">
            <ul className="active-filters-list">
                { this.renderPills() }
            </ul>
            <button className="clear-filters-button" onClick={this.clearFilters}>
                <span>Clear filters</span>
                <img src={iconCancel} alt="icon cancel" />
            </button>
        </div>
    )

    renderFilterDrawerHeader = () => (
        <div className="filter-drawer-header" onClick={this.toggleFiltersDrawer}>
            <p>
                <img src={iconFilter} alt="Funnel." />
                <span>Filters</span>
            </p>
            <button>&#65293;</button>
        </div>
    )

    renderFilterDrawerFooter = () => (
        <div className="filter-drawer-footer">
            <button onClick={this.toggleFiltersDrawer}>Show results</button>
        </div>
    )

    render = () => (
        <div className={`sidebar container ${this.state.isFiltersDrawerActive ? 'sidebar--drawer' : ''}`} ref={this.sidebar}>
            {this.state.isFiltersDrawerActive ? this.renderFilterDrawerHeader() : null}
            {this.state.filters.some(filter => filter.controls.some(control => control.isActive)) ? this.renderActiveFilters() : null}
            <div className="filters-container">
                {this.state.filters.map((filter, index) => this.renderFilter(filter, index))}
            </div>
            {this.state.isFiltersDrawerActive ? this.renderFilterDrawerFooter() : null}
        </div>
    )
}

ExploreSidebar.propTypes = {
    setPageResults: PropTypes.func
};

export default ExploreSidebar;