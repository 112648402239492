import React, { Component } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import Slider from 'react-slick';
import iconLocation from '../images/icons/icon-location.svg';
import iconWebinar from '../images/icons/icon-webinar.svg';
import eventImageFallback from '../images/events/event-4.jpg';

/**
 *
 */
export default class EventListings extends Component {

	/**
	 * Component constructor
	 * @param props
	 */
    constructor(props) {
        super(props);
        this.data = props;
    }



    /**
     * Render location
     * @param {String} location 
     * @returns {Object}
     */
    renderLocation (location, marker) {
        return  location ? 
            (<>
                <img className="pin-icon" src={marker} alt="Location marker." />
                {location}
            </>) :
            null;
    }

    /**
     * Render location details
     * @param {Object} eventType 
     * @param {String} location 
     * @returns {Object}
     */
    renderLocationDetails (eventType, location) {

        let marker = iconLocation;

        if(eventType.name === 'Webinar')
        {
            marker = iconWebinar;
        }



        return (
            <address>
                { this.renderLocation(location, marker) }
            </address>
        );
    }


    /**
     * Render event image
     * @param {Object} image 
     * @returns {Object}
     */
    renderEventImage (image, alt) {
        return (
            <div className="image-container">
                {
                    (image && image.localFile && image.localFile.childImageSharp) ?
                        <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} className="event-picture" alt={alt} /> :
                        <img className="event-picture" src={eventImageFallback} alt="Stylized calendar." />
                }
            </div>
        );
    }

    renderEventCard (event, key) {
        return (
            <div key={key} className="col-parrent">
                <div className="col">
                    <Link to={event.path.alias}>
                      <strong>{moment(event.field_start_date).format('D')}</strong>
                      <small>{moment(event.field_start_date).format('MMM')}</small>
                      <h5>
                        {event.title}
                      </h5>
                    </Link>
                    <p>{event.field_description.summary}</p>
                    { this.renderLocationDetails(event.relationships.field_event_type, event.field_location_short) }
                    <div className="d-none d-sm-block">
                      <Link to={event.path.alias}>
                        { this.renderEventImage(event.relationships.field_event_widget_image, event.field_event_widget_image.alt) }
                      </Link>
                    </div>
                </div>
                <div className="d-block d-sm-none">
                  <Link to={event.path.alias}>
                    { this.renderEventImage(event.relationships.field_event_widget_image, event.field_event_widget_image.alt) }
                  </Link>
                </div>
            </div>
        );
    }

	/**
	 * Component renderer
	 * @returns {*}
	 */
    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                { breakpoint: 767, settings: { slidesToShow: 1 } },
                { breakpoint: 1024, settings: { slidesToShow: 2 } },
                { breakpoint: 100000, settings: { slidesToShow: 4 } }
            ]
        };
        return (
            <StaticQuery
                query={graphql`
                    query EventsListingCardsQuery {
                      allNodeEvent(limit: 4, sort: {fields: field_start_date, order: ASC}, filter: {path: {alias: {glob: "!/system/*"}}, status: {eq: true}}) {
                        edges {
                          node {
                            title
                            created

                            field_end_date
                            field_start_date


                            start_month_year:field_start_date(formatString: "MMMM YYYY")
                            start_day:field_start_date(formatString: "d")
                            start_month_short:field_start_date(formatString: "MMM")

                            field_location
                            field_location_short
                            field_registration_link {
                              uri
                              title
                            }
                            field_description {
                              processed
                              summary
                            }

                            path {
                              alias
                            }

                            field_event_widget_image{
                              alt
                            }

                            relationships {

                              field_components {
                                __typename
                              }

                              field_event_type {
                                name
                              }

                              field_event_widget_image {
                                localFile {
                                  childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                `}
                render={data => (
                    <section className="events-webinars">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2>Events and webinars</h2>
                                </div>
                            </div>
                            <div className="parrent">
                                <Slider {...settings}>
                                    { data.allNodeEvent.edges.map((edge, key) => this.renderEventCard(edge.node, key)) }
                                </Slider>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Link className="btn btn-blue btn-lg" to="/events/">See all events</Link>
                                </div>
                            </div>
                        </div>
                    </section>                    
                )}
            />
        )
    }
};


/**
 * @TODO Implement
 */
export const EventListingsQuery = graphql`
fragment ParagraphsEventListing on paragraph__event_listings {
  drupal_internal__id
}
`;

