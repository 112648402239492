import React, {Component} from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import DatePicker from './react-datetime-master/DateTime';
import iconArrow from'../images/icons/icon-arrow.svg';
import iconCalendar from'../images/icons/icon-calendar.svg';
import iconFilter from '../images/icons/icon-filter.svg';
import clearIcon from '../images/icons/icon-cancel.svg';
import { deepCloneArray } from '../utils/deep-clone';
import removeObjectDuplicates from '../utils/remove-object-duplicates';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from './internal/loader';
import filterCallbacks from '../utils/blog-filter-callbacks';

import 'react-datetime/css/react-datetime.css';

/**
 * This components renders blog posts
 */
export default class Blog extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            isFiltersDrawerActive: false,
            areMoreFilterControlsDisplayed: false,
            areAudienceControlsCollapsed: false,
            blogPosts: [],            
            startDate: null,
            endDate: null,
            tags: [],
            audiences: [],
            matchingBlogPosts: []
        };
    }

    /**
     *
     * @param blogPost
     * @param key
     * @returns {*}
     */
    renderBlogPost(blogPost, key) {
        return (
            <li className="blog-post-container" key={key}>
                <div className="blog-post-details">
                    <h2>
                        <Link to={blogPost.path.alias}>{blogPost.title}</Link>
                    </h2>
                    <div className="blog-post-publish-details">
                        <span>
                            By {blogPost.field_blog_author}
                        </span>
                        <span>
                            {blogPost.created_formatted}
                        </span>
                    </div>
                    <p>
                        {blogPost.field_summary}
                    </p>
                    <div className="filters-container">
                        <ul className="filter-list">
                            {blogPost.relationships.field_blog_tags.map((tag, key) => 
                                <li className="filter-item">
                                    <button
                                        className={`filter-item-control ${this.isActiveTag(tag.id) ? 'filter-item-control--active' : ''}`}
                                        onClick={() => this.toggleTag(tag.id)}
                                        >
                                        <span>{tag.name}</span>
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                {this.renderBlogImage(blogPost)}
            </li>
        )
    }

    renderBlogImage(blogPost)
    {
        if(!blogPost.relationships.field_blog_image || !blogPost.relationships.field_blog_image.localFile || !blogPost.relationships.field_blog_image.localFile.childImageSharp)
            return null;

        return (
            <div className="blog-post-image">
                <Link to={blogPost.path.alias}>
                    <GatsbyImage image={blogPost.relationships.field_blog_image.localFile.childImageSharp.gatsbyImageData}
                         className=""
                         alt={blogPost.field_blog_image.alt} />
                </Link>
            </div>
        );
    }

    toggleTag (tagId) {
        const newTags = deepCloneArray(this.state.tags);
        const match = newTags.filter(tag => tag.id === tagId)[0];
        match.isActive = !match.isActive;
        this.setState({ tags: newTags });
        this.filterBlogPostsByTag(newTags);
    }

    toggleAudience (audienceId) {
        const newAudiences = deepCloneArray(this.state.audiences);
        const match = newAudiences.filter(audience => audience.id === audienceId)[0];
        match.isActive = !match.isActive;
        this.setState({ audiences: newAudiences });
    }

    renderFiltersCloseButton () {
        return (
            <div className="filters-drawer-header" onClick={() => this.toggleFiltersDrawer()}>
                <div>
                    <img src={iconFilter} alt="Funnel."/>
                    <span>Filters</span>
                </div>
                <button>&#65293;</button>
            </div>
        );
    }

    renderFooter () {
        return (
            <div className="filters-drawer-footer">
                <button className="btn btn-blue btn-sm" onClick={() => this.toggleFiltersDrawer()}>
                    Show Results
                </button>
            </div>
        );
    }

    renderFilterControls () {
        const tags = this.state.areMoreFilterControlsDisplayed ? this.state.tags : this.state.tags.slice(0, 3);
        return (
            <div className="filters-container">
                <ul className="filter-list">
                    {
                        tags.map((tag, key) =>
                            <li className="filter-item" key={key}>
                                <button
                                    className={`filter-item-control ${this.isActiveTag(tag.id) ? 'filter-item-control--active' : ''}`}
                                    onClick={() => this.toggleTag(tag.id)}
                                    >
                                    <span>{tag.name}</span>
                                </button>
                            </li>
                        )
                    }
                </ul>
                {
                    this.state.tags.length > 3 ?
                        <button className="expand-filters" onClick={() => this.onToggleMoreFilterControls()}>
                            <span>View { this.state.areMoreFilterControlsDisplayed ? 'fewer' : 'more' } filters</span>
                            <span>{ this.state.areMoreFilterControlsDisplayed ? '\uFF0D' : '\uFF0B' }</span>
                        </button> : 
                        null
                }
                
            </div>
        );
    }

    isActiveTag (tagId) {
        return this.state.tags.some(tag => tag.id === tagId && tag.isActive);
    }

    onToggleMoreFilterControls () {
        this.setState({ areMoreFilterControlsDisplayed: !this.state.areMoreFilterControlsDisplayed });
    }

    renderSidebar () {
        return (
            <aside className={`col-lg-4 ${this.state.isFiltersDrawerActive ? 'filter-drawer--active' : ''}`}>
                {this.renderFiltersCloseButton()}
                {this.renderFilterControls()}
                {/* ORG-834 : Hiding Audience filter temporarily. {this.renderAudienceControls()} */}
                {this.renderDateControls()}
                {this.renderFooter()}
            </aside>
        );
    }

    onToggleAudienceControls () {
        this.setState({ areAudienceControlsCollapsed: !this.state.areAudienceControlsCollapsed });
    }

    renderAudienceControls () {
        return (
            <div className={`audience-controls-container ${this.state.areAudienceControlsCollapsed ? '' : 'audience-controls-container--is-expanded'}`}>
                <button className="audience-controls-button" onClick={() => this.onToggleAudienceControls()}>
                    <span>Audience</span>
                    <img src={iconArrow} alt="Arrow." />
                </button>
                <ul className="audience-list">
                    {this.state.audiences.map((audience, key) => 
                        <li key={key}className="audience-item">
                            <input id={audience.id} type="checkbox" onChange={() => this.toggleAudience(audience.id)} />
                            <label htmlFor={audience.id}>{audience.name}</label>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    onToggleDateControls () {
        this.setState({ areDateControlsCollapsed: !this.state.areDateControlsCollapsed });
    }

    isSinceOldestBlogPost (date) {
        if (this.state.blogPosts.length > 1) {
            const formattedDate = moment(date).format('YYYY-M-DD');
            const oldestBlogPostDate = this.state.blogPosts
                .reduce((accumulator, next) => moment(accumulator.created).isBefore(next.created) ?
                    accumulator.created : next.created);
            const formattedOldestBlogPostDate = moment(oldestBlogPostDate).format('YYYY-M-DD');
            return moment(formattedDate).isSame(formattedOldestBlogPostDate) || moment(formattedDate).isAfter(formattedOldestBlogPostDate);
        } else {
            return true;
        }
    }
    
    renderDatePickerMonth (props, month) {
        return (
            <td {...props}>{moment(++month, 'M').format('MMMM')}</td>
            );
        }
        
        isBeforeNewestBlogPost (date) {
            if (this.state.blogPosts.length > 1) {
                const formattedDate = moment(date).format('YYYY-M-DD');
                const newestBlogPostDate = this.state.blogPosts
                    .map(blogPost => blogPost.created)
                    .reduce((accumulator, next) => moment(accumulator).isAfter(next) ?
                    accumulator : next);
                const formattedNewestBlogPostDate = moment(newestBlogPostDate).format('YYYY-M-DD');
            return moment(formattedDate).isSame(formattedNewestBlogPostDate) || moment(formattedDate).isBefore(formattedNewestBlogPostDate);
        } else {
            return true;
        }
    }

    isValidDate (date) {
        return this.isSinceOldestBlogPost(date) && this.isBeforeNewestBlogPost(date);
    }

    setDate (dateBoundaryName, date) {
        this.setState({ [`${dateBoundaryName}Date`]: date });
    }

    renderDateControls () {
        return (
            <div className={`date-controls-container ${this.state.areDateControlsCollapsed ? '' : 'date-controls-container--is-expanded'}`}>
                <button className="date-controls-button" onClick={() => this.onToggleDateControls()}>
                    <span>Date range</span>
                    <img src={iconArrow} alt="Arrow." />
                </button>
                <div className="date-controls">
                    <label onClick={e => e.preventDefault()}>
                        <span>From</span>
                        <div className="date-picker-container">
                            <DatePicker
                                isValidDate={(date) => this.isValidDate(date)}
                                value={this.state.startDate}
                                onChange={value => this.setDate('start', value)}
                                renderMonth={(props, month) => this.renderDatePickerMonth(props, month)}
                                dateFormat="MMMM YYYY"
                                closeOnSelect={true}
                                timeFormat={false} />
                        </div>
                        <img src={iconCalendar} alt="Calendar." />
                    </label>
                    <label onClick={e => e.preventDefault()}>
                        <span>To</span>
                        <div className="date-picker-container">
                            <DatePicker
                                isValidDate={date => this.isValidDate(date)}
                                value={this.state.endDate}
                                onChange={value => this.setDate('end', value)}
                                renderMonth={(props, month) => this.renderDatePickerMonth(props, month)}
                                dateFormat="MMMM YYYY"
                                closeOnSelect={true}
                                timeFormat={false} />
                        </div>
                        <img src={iconCalendar} alt="Calendar." />
                    </label>
                </div>
                {
                    this.shouldRenderDateClearButton() ? 
                        <div>
                        <button className="custom-clear-button" onClick={() => this.clearDates()}>
                            Clear date range
                            <img src={clearIcon} alt="" />
                        </button></div> :
                        null

                }
            </div>
        );
    }

    clearDates () {
        this.setState({ startDate: '', endDate: '' });
    }

    shouldRenderDateClearButton () {
        return this.state.startDate || this.state.endDate;
    }

    toggleFiltersDrawer () {
        this.setState({ isFiltersDrawerActive: !this.state.isFiltersDrawerActive });
    }

    fixEndDate () {

        return this.state.endDate && moment(this.state.endDate).add(1, 'M');
    }

    isPassingFilters (blogPost) {        
        return filterCallbacks.every(filterCallback => filterCallback(blogPost, {...this.state, endDate: this.fixEndDate()}));
    }

    filterBlogPostsByTag (newTags) {
        const activeTagIds = newTags.filter(tag => tag.isActive).map(tag => tag.id);
         
                const matchingBlogPosts = this.state.blogPosts.filter(post => {
                    for (const postTag of post.relationships.field_blog_tags) {
                      if (activeTagIds.includes(postTag.id)) {
                        return true; 
                      }
                    }
                    return false; 
                  });
                  this.setState(
                      {
                          matchingBlogPosts: matchingBlogPosts
                      }
                  )
    }

    /**
     *
     * @param blogPosts
     */
    renderBlogPosts() { 
        const initialBlogPosts = this.state.matchingBlogPosts.length === 0 ? this.state.blogPosts : this.state.matchingBlogPosts;
             
        return (
            <main className="col-lg-8">
                <div className="filters-drawer-control" onClick={() => this.toggleFiltersDrawer()}>
                    <div>
                        <img src={iconFilter} alt="Funnel."/>
                        <span>Filters</span>
                    </div>
                    <button>
                        <span>&#65291;</span>
                    </button>
                </div>
                <ul className="blog-posts-list">
                    <InfiniteScroll
                        loadMore={() => this.loadMoreBlogPosts()}
                        hasMore={this.state.blogPosts.some(blogPost => !blogPost.isLoaded)}
                        loader={<Loader />}>
                        {
                            initialBlogPosts
                                .filter(blogPost => blogPost.isLoaded)
                                .map((blogPost, key) => this.renderBlogPost(blogPost, key))
                        }
                    </InfiniteScroll>
                </ul>
            </main>
        )
    }

    findNextUnloadedBlogPost (blogPosts) {
        return blogPosts.find(blogPost => blogPost.isLoaded);
    }

    loadMoreBlogPosts () {
        const batchSize = 5;
        const updatedBlogPosts = deepCloneArray(this.state.blogPosts);
        for (let i = 0; i < batchSize; i++) {
            const nextUnloadedBlogPost = this.findNextUnloadedBlogPost(updatedBlogPosts);
            if (nextUnloadedBlogPost) {
                nextUnloadedBlogPost.isLoaded = true;
            } else {
                break;
            }
        }
        this.setState({ blogPosts: updatedBlogPosts });
    }

    loadInitialBatch (blogPosts) {
        const initialBatchSize = blogPosts?.length;
        for (let i = 0; i < initialBatchSize; i++) {
            if (blogPosts[i]) {
                blogPosts[i].isLoaded = true;
            } else {
                break;
            }
        }
    }

    storeInState (edges) {
        if (!this.state.blogPosts.length) {
            const blogPosts = edges.map(edge => edge.node);
            this.loadInitialBatch(blogPosts);

            const duplicateAudiences = blogPosts.map(blogPost => blogPost.relationships.field_blog_audience);
            const uniqueAudiences = removeObjectDuplicates(duplicateAudiences, 'id');
            
            const duplicateTags = blogPosts
                .map(blogPost => blogPost.relationships.field_blog_tags)
                .reduce((acc, val) => acc.concat(val), []);
            const uniqueTags = removeObjectDuplicates(duplicateTags, 'id');
            this.setState({
                blogPosts,
                tags: uniqueTags,
                audiences: uniqueAudiences
            });
        }
    }

    /**
     * Render menu
     * @returns {*}
     */
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query BlogPostListingQuery {
                      allNodeBlogPost(sort: {fields: created, order: DESC}, filter: {path: {alias: {glob: "!/system/*"}}, status: {eq: true}}) {
                        edges {
                          node {
                            title
                            field_blog_author
                            field_blog_image {
                                alt
                            }
                            field_summary
                            created
                            created_formatted:created(formatString: "MMMM DD, YYYY")
                            path {
                              alias
                            }
                            relationships {
                              field_components {
                                __typename
                              }
                              field_blog_image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width:300 layout: FIXED)
                                    }
                                }
                              }
                              field_blog_audience {
                                id: drupal_internal__tid
                                name
                              }
                              field_blog_tags {
                                id: drupal_internal__tid
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                `}
                render={data => {
                    this.storeInState(data.allNodeBlogPost.edges);

                    return (
                        <div className="container blogs">
                            <div className="row">
                                {this.renderSidebar()}
                                {this.renderBlogPosts()}
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
};
