export const usaStates = {
    AL: 'ALABAMA',
    AK: 'ALASKA',
    AZ: 'ARIZONA',
    AR: 'ARKANSAS',
    CA: 'CALIFORNIA',
    CO: 'COLORADO',
    CT: 'CONNECTICUT',
    DE: 'DELAWARE',
    DC: 'DISTRICT OF COLUMBIA',
    FL: 'FLORIDA',
    GA: 'GEORGIA',
    HI: 'HAWAII',
    ID: 'IDAHO',
    IL: 'ILLINOIS',
    IN: 'INDIANA',
    IA: 'IOWA',
    KS: 'KANSAS',
    KY: 'KENTUCKY',
    LA: 'LOUISIANA',
    ME: 'MAINE',
    MD: 'MARYLAND',
    MA: 'MASSACHUSETTS',
    MI: 'MICHIGAN',
    MN: 'MINNESOTA',
    MS: 'MISSISSIPPI',
    MO: 'MISSOURI',
    MT: 'MONTANA',
    NE: 'NEBRASKA',
    NV: 'NEVADA',
    NH: 'NEW HAMPSHIRE',
    NJ: 'NEW JERSEY',
    NM: 'NEW MEXICO',
    NY: 'NEW YORK',
    NC: 'NORTH CAROLINA',
    ND: 'NORTH DAKOTA',
    OH: 'OHIO',
    OK: 'OKLAHOMA',
    OR: 'OREGON',
    PA: 'PENNSYLVANIA',
    PR: 'PUERTO RICO',
    RI: 'RHODE ISLAND',
    SC: 'SOUTH CAROLINA',
    SD: 'SOUTH DAKOTA',
    TN: 'TENNESSEE',
    TX: 'TEXAS',
    UT: 'UTAH',
    VT: 'VERMONT',
    VI: 'VIRGIN ISLANDS',
    VA: 'VIRGINIA',
    WA: 'WASHINGTON',
    WV: 'WEST VIRGINIA',
    WI: 'WISCONSIN',
    WY: 'WYOMING',
};

export const countries = [
    'United States of America',
    'Canada',
    'Bulgaria',
    'China',
    'Czech Republic',
    'France',
    'Germany',
    'Hong Kong',
    'Ireland',
    'Italy',
    'Japan',
    'Korea, Republic Of',
    'Lebanon',
    'Mexico',
    'Qatar',
    'Spain',
    'Switzerland',
    'United Kingdom'
]

const findUsaState = state => {
    const usaState = Object.entries(usaStates).find(usaState => usaState.includes(state.toUpperCase()));
    return usaState ? usaState[0] : '';
};

const getUsaState = state => state ? findUsaState(state) : '';

export default getUsaState;