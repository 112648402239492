const exploreFilters = [
    {
        title: '',
        isExpanded: true,
        controls: [
            {
                type: 'checkbox',
                name: 'Accepts first-year applications',
                id: 'accepts-first-year-applications',
                group: 'fytr'
            },
            {
                type: 'checkbox',
                name: 'Accepts transfer applications',
                id: 'accepts-transfer-applications',
                group: 'fytr'
            }
        ]
    },
    {
        title: 'Location',
        isExpanded: true,
        controls: [
            {
                type: 'text',
                name: 'Country',
                id: 'countryLocation',
                group: 'location'
            },
            {
                type: 'text',
                name: 'State',
                id: 'stateLocation',
                group: 'location'
            }
        ]
    },
    {
        title: 'Region',
        isExpanded: false,
        controls: [
            {
                type: 'checkbox',
                name: 'New England',
                id: 'region-new-england',
                lat: 41.1187906,
                lng: -76.7787915,
                zoom: 6,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Mid-Atlantic',
                id: 'region-mid-atlantic',
                lat: 44.365905,
                lng: -70.7667671,
                zoom: 6,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'South',
                id: 'region-south',
                lat: 31.524405,
                lng: -96.5805536,
                zoom: 6,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Southwest',
                id: 'region-southwest',
                lat: 31.524405,
                lng: -96.5805536,
                zoom: 6,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Midwest',
                id: 'region-midwest',
                lat: 42.5368021,
                lng: -92.2252312,
                zoom: 5,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'West',
                id: 'region-west',
                lat: 40.3717396,
                lng: -110.6340483,
                zoom: 5,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Alaska',
                id: 'region-alaska',
                lat: 60.1282451,
                lng: -176.4761128,
                zoom: 4,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Hawaii',
                id: 'region-hawaii',
                lat: 20.4456005,
                lng: -159.7496576,
                zoom: 7,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'United Kingdom',
                id: 'region-uk',
                lat: 51.5074,
                lng: 0.1,
                zoom: 7,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Canada',
                id: 'region-canada',
                lat: 55.522023,
                lng: -112.507534,
                zoom: 7,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Europe',
                id: 'region-europe',
                lat: 46.831073,
                lng: 6.170653,
                zoom: 4,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Asia',
                id: 'region-asia',
                lat: 49.480617,
                lng: 79.771492,
                zoom: 4,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'Australia',
                id: 'region-australia',
                lat: -24.992764,
                lng: 115.2286132,
                zoom: 4,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'South America',
                id: 'region-south-america',
                lat: -10.657909,
                lng: -53.945438,
                zoom: 4,
                group: 'region'
            },
            {
                type: 'checkbox',
                name: 'International',
                id: 'region-international',
                lat: 39.4237667,
                lng: -99.2144423,
                zoom: 2,
                group: 'region'
            }
        ]
    },
    {
        title: 'Application for first-year students',
        isExpanded: false,
        controls: [
            {
                type: 'checkbox',
                name: 'Accepts self-reported test scores - First Year',
                id: 'srs-first-year',
                group: 'first-year'
            },
            {
                type: 'checkbox',
                name: 'Charges no application fee - First Year',
                id: 'caf-first-year',
                group: 'first-year'
            },
            {
                type: 'checkbox',
                name: 'No personal essay required - First Year',
                id: 'per-first-year',
                group: 'first-year'
            },
            {
                type: 'checkbox',
                name: 'No letter of recommendation required - First Year',
                id: 'rec-first-year',
                group: 'first-year'
            },
            {
                type: 'checkbox',
                name: 'Test Optional/Flexible - First Year',
                id: 'tp-first-year',
                group: 'first-year'
            },
        ]
    },
    {
        title: 'Application for transfer students',
        isExpanded: false,
        controls: [
            {
                type: 'checkbox',
                name: 'Accepts self-reported test scores - Transfer',
                id: 'srs-transfer-student',
                group: 'transfer-student'
            },
            {
                type: 'checkbox',
                name: 'Charges no application fee - Transfer',
                id: 'caf-transfer-student',
                group: 'transfer-student'
            },
            {
                type: 'checkbox',
                name: 'Guaranteed admission program - Transfer',
                id: 'gap-transfer-student',
                group: 'transfer-student'
            },
            {
                type: 'checkbox',
                name: 'No personal essay required - Transfer',
                id: 'per-transfer-student',
                group: 'transfer-student'
            },
            {
                type: 'checkbox',
                name: 'No letter of recommendation required - Transfer',
                id: 'rec-transfer-student',
                group: 'transfer-student'
            },
            {
                type: 'checkbox',
                name: 'Test Optional/Flexible - Transfer',
                id: 'tp-transfer-student',
                group: 'transfer-student'
            },
        ]
    },
    {
        title: 'Campus setting',
        isExpanded: false,
        controls: [
            {
                type: 'checkbox',
                name: 'Rural',
                id: 'campus-rural',
                group: 'campus'
            },
            {
                type: 'checkbox',
                name: 'Urban',
                id: 'campus-urban',
                group: 'campus'
            },
            {
                type: 'checkbox',
                name: 'Suburban',
                id: 'campus-suburban',
                group: 'campus'
            },
        ]
    },
    {
        title: 'Financial aid',
        isExpanded: false,
        controls: [

            {
                type: 'checkbox',
                name: 'Offers aid for international students',
                id: 'finaid-international',
                group: 'finaid'
            },
            {
                type: 'checkbox',
                name: 'Offers merit-based aid',
                id: 'finaid-merit-based',
                group: 'finaid'
            },
            {
                type: 'checkbox',
                name: 'Offers need-based aid',
                id: 'finaid-need-based',
                group: 'finaid'
            },
        ]
    },
    {
        title: 'Minority serving institution',
        isExpanded: false,
        controls: [

            {
                type: 'checkbox',
                name: 'Alaskan Native and Native Hawaiian-Serving Institution (AANHI)',
                id: 'msi-aanhi',
                group: 'msi'
            },
            {
                type: 'checkbox',
                name: 'Asian American and Native American Pacific Islander Serving Institution (AANAPISI)',
                id: 'msi-aanapisi',
                group: 'msi'
            },
            {
                type: 'checkbox',
                name: 'Hispanic-Serving Institution (HSI)',
                id: 'mission-hsi',
                group: 'mission'
            },
            {
                type: 'checkbox',
                name: 'Historically Black College and University (HBCU)',
                id: 'mission-hbcu',
                group: 'mission'
            },
            {
                type: 'checkbox',
                name: 'Native American Serving Nontribal Institution (NASNTI)',
                id: 'msi-nasnti',
                group: 'msi'
            },
            {
                type: 'checkbox',
                name: 'Predominately Black Institution (PBI)',
                id: 'msi-pbi',
                group: 'msi'
            },
        ]
    },
    {
        title: 'Type',
        isExpanded: false,
        controls: [
            {
                type: 'checkbox',
                name: 'Public',
                id: 'type-public',
                group: 'type'
            },
            {
                type: 'checkbox',
                name: 'Private',
                id: 'type-private',
                group: 'type'
            },]
    },
    {
        title: 'Total Enrollment Size',
        isExpanded: false,
        controls: [

            {
                type: 'checkbox',
                name: 'Small (2,000 and under)',
                id: 'tes-small',
                group: 'tes'
            },
            {
                type: 'checkbox',
                name: 'Medium (2,001 to 14,999)',
                id: 'tes-medium',
                group: 'tes'
            },
            {
                type: 'checkbox',
                name: 'Large (15,000+)',
                id: 'tes-large',
                group: 'tes'
            },
        ]
    },
    {
        title: 'Specialized mission',
        isExpanded: false,
        controls: [
            {
                type: 'checkbox',
                name: 'Women\'s College',
                id: 'mission-women-only',
                group: 'mission'
            },
            {
                type: 'checkbox',
                name: 'Men\'s College',
                id: 'mission-men-only',
                group: 'mission'
            },
            {
                type: 'checkbox',
                name: 'Coordinate',
                id: 'mission-coordinate',
                group: 'mission'
            },
            {
                type: 'checkbox',
                name: 'Co-Ed',
                id: 'mission-co-ed',
                group: 'mission'
            },
            {
                type: 'checkbox',
                name: 'Religious Affiliation',
                id: 'mission-religious',
                group: 'mission'
            }
        ]
    },
];

export default exploreFilters;
